<template>
  <ion-item lines="none">
    <ion-toggle
      slot="start"
      name="blueberry"
      :checked="theme === 'dark'"
      @ionChange="onDarkModeToggleChange"
    />
    <ion-label class="ion-text-uppercase">
      {{ $t('Dark mode') }}
    </ion-label>
  </ion-item>
</template>

<script lang="ts">
import { IonToggle, IonLabel, IonItem } from '@ionic/vue'

import { ref } from 'vue'
import { useStore } from '@/store'

export default {
  name: 'Profile',
  components: {
    IonToggle,
    IonLabel,
    IonItem,
  },
  setup() {
    const { state } = useStore()
    const theme = ref('light')
    const onDarkModeToggleChange = (ev: any) => {
      const newValue = theme.value === 'dark' ? 'light' : 'dark'
      document.body.classList.toggle('dark', ev.detail.checked)
      theme.value = newValue
    }

    return {
      theme,
      onDarkModeToggleChange,
    }
  },
}
</script>

<style scoped>
ion-toggle {
  --handle-background-checked: #6200ee;
  --background-checked: #6200ee61;
}
</style>
