
import { IonIcon } from '@ionic/vue'

import { useStore } from '@/store'

import { settingsOutline } from 'ionicons/icons'

export default {
  name: 'Profile',
  components: {
    IonIcon,
  },
  setup() {
    const { state } = useStore()

    return {
      settingsOutline,
    }
  },
}
