
import { IonToggle, IonLabel, IonItem } from '@ionic/vue'

import { ref } from 'vue'
import { useStore } from '@/store'

export default {
  name: 'Profile',
  components: {
    IonToggle,
    IonLabel,
    IonItem,
  },
  setup() {
    const { state } = useStore()
    const theme = ref('light')
    const onDarkModeToggleChange = (ev: any) => {
      const newValue = theme.value === 'dark' ? 'light' : 'dark'
      document.body.classList.toggle('dark', ev.detail.checked)
      theme.value = newValue
    }

    return {
      theme,
      onDarkModeToggleChange,
    }
  },
}
