<template>
  <router-link to="/settings">
    <div class="grey-container settings">
      <ion-icon :icon="settingsOutline" color="secondary" />
      <p class="text-color-secondary ion-text-uppercase">
        {{ $t('Settings') }}
      </p>
    </div>
  </router-link>
</template>

<script lang="ts">
import { IonIcon } from '@ionic/vue'

import { useStore } from '@/store'

import { settingsOutline } from 'ionicons/icons'

export default {
  name: 'Profile',
  components: {
    IonIcon,
  },
  setup() {
    const { state } = useStore()

    return {
      settingsOutline,
    }
  },
}
</script>

<style scoped>
.grey-container {
  border-radius: 30px;
}

ion-icon {
  font-size: 25px;
}

.settings {
  display: flex;
  align-items: center;
}
.settings ion-icon {
  width: 38px;
}

p {
  display: inline-block;
  margin: 15px;
}
</style>
