<template>
  <ion-page>
    <ion-content :fullscreen="true" style="text-align: center;">
      <div class="white-container">
        <back-link text="BACK" />
        <grey-box-list :content="menuItems" />
        <settings-link />
        <ion-row class="grey-container">
          <mode-switch />
        </ion-row>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import BackLink from '@/components/settings/BackLink.vue'
import GreyBoxList from '@/components/settings/GreyBoxList.vue'
import SettingsLink from '@/components/settings/SettingsLink.vue'
import ModeSwitch from '@/components/settings/ModeSwitch.vue'

import { IonContent, IonPage, IonRow } from '@ionic/vue'

import { useStore } from '@/store'

import {
  arrowBack,
  peopleSharp,
  trophySharp,
  rocketSharp,
  readerSharp,
  playCircleSharp,
  bulbSharp,
  walkSharp,
  chevronForwardSharp,
} from 'ionicons/icons'

export default {
  name: 'Profile',
  components: {
    BackLink,
    GreyBoxList,
    SettingsLink,
    ModeSwitch,
    IonContent,
    IonPage,
    IonRow,
  },
  setup() {
    const { state } = useStore()
    return {
      arrowBack,
      peopleSharp,
      trophySharp,
      rocketSharp,
      readerSharp,
      playCircleSharp,
      bulbSharp,
      walkSharp,
      chevronForwardSharp,
    }
  },
  data() {
    return {
      menuItems: [
        { text: 'My posts', icon: walkSharp, link: '/tabs/my-posts' },
        { text: 'My tasks', icon: readerSharp, link: '/tabs/home/todos' },
        { text: 'Friends', icon: peopleSharp, link: '/tabs/friends' },
        { text: 'Challenges', icon: trophySharp, link: '/tabs/challenges' },
        { text: 'Programs', icon: rocketSharp, link: '/tabs/programs' },
        {
          text: 'Training videos',
          icon: playCircleSharp,
          link: '/tabs/training-videos',
        },
        { text: 'Hockey tips', icon: bulbSharp, link: '/tabs/tips' },
      ],
    }
  },
}
</script>

<style scoped>
.grey-container,
.white-container {
  border-radius: 30px;
  text-align: left;
}
</style>
